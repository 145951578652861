body {
  font-family: Ubuntu, sans-serif;
  margin: 0;
  padding-top: 50px;
}
@media screen and (max-width: 265px) {
  body {
    padding-top: 450px;
  }
}
@media screen and (min-width: 265px) and (max-width: 390px) {
  body {
    padding-top: 250px;
  }
}
@media screen and (min-width: 390px) and (max-width: 480px) {
  body {
    padding-top: 200px;
  }
}
@media screen and (min-width: 480px) and (max-width: 520px) {
  body {
    padding-top: 150px;
  }
}
@media screen and (min-width: 520px) and (max-width: 1100px) {
  body {
    padding-top: 100px;
  }
}
